import { ref } from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import router from '@/router'
import store from '@/store'

export default function getBusiness() {
  const businessData = ref({})
  const getBusinessData = () => axiosInstances.entities.get(`internalops/commercial_entity/
  ${router.currentRoute.params.id ?? store.getters['mainEntity/getEntityId']}`)
    .then(res => {
      businessData.value = {
        ...res.data.data,
        media_files: res.data.data.media_files.map(image => image.url),
        address: res.data.data.address.length !== 0 ? res.data.data.address : [{}],
        logo: [res.data.data.logo],
        cover: [res.data.data.cover],
      }
    })

  return {
    businessData, getBusinessData,
  }
}
