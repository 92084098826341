import {
  ref,
} from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import router from '@/router'
import store from '@/store'

export default function getInpo() {
  const inpoData = ref({})

  const getInpoData = () => {
    axiosInstances.entities.get(`internalops/entity/${router.currentRoute.params.id ?? store.getters['mainEntity/getEntityId']}`).then(res => {
      const inpo = res.data.data
      inpoData.value = {
        ...inpo,
        paymentdetails: inpo.paymentdetails ?? {}, // this because payment details aren't found in some inpos
        address: inpo.address.length !== 0 ? inpo.address : [{}], // this because address aren't found in some inpos
        logo: [inpo.logo],
        cover: [inpo.cover],
        sign_path: [inpo.sign_path],
        taxReceiptAddress: {
          street: inpo.taxReceiptAddress?.street_tax,
          city: inpo.taxReceiptAddress?.city_tax,
          state: inpo.taxReceiptAddress?.state_tax,
          country: inpo.taxReceiptAddress?.country_tax,
          postal_code: inpo.taxReceiptAddress?.postal_code_tax,
          lat: inpo.taxReceiptAddress?.lat_tax,
          lng: inpo.taxReceiptAddress?.lng_tax,
        },
      }
    })
  }

  return { inpoData, getInpoData }
}
