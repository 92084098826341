<template>
  <div>
    <b-row>
      <!-- Payment Details -->
      <b-col class="mb-3">
        <h4 class="mb-2">
          Payment Details
        </h4>
        <ul class="list-unstyled">
          <li class="mb-1">
            <span class="mr-2">payment type:</span>
            <span>{{ entity.paymentdetails.payment_type }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">business type:</span>
            <span>{{ entity.paymentdetails.business_type }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">transit number(branch number):</span>
            <span>{{ entity.paymentdetails.transit_number }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">institution number(bank number):</span>
            <span>{{ entity.paymentdetails.institution_number }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">account number:</span>
            <span>{{ entity.paymentdetails.account_number }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">charitable number:</span>
            <span>{{ entity.paymentdetails.charitable_number }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">incorporation number:</span>
            <span>{{ entity.paymentdetails.phoneNum }}</span>
          </li>
        </ul>
      </b-col>
      <!-- Owners -->
      <b-col>
        <h4 class="mb-2">
          Owners
        </h4>
        <ul class="list-unstyled">
          <li class="mb-1">
            <span class="mr-2">account holder name:</span>
            <span>{{ entity.paymentdetails.account_holder_name }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">account holder position:</span>
            <span>{{ entity.paymentdetails.account_holder_position }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">business name:</span>
            <span>{{ entity.paymentdetails.business_name }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">legal name:</span>
            <span>{{ entity.paymentdetails.legal_name }}</span>
          </li>
          <li class="mb-1">
            <span class="mr-2">account holder DOB:</span>
            <span>{{ entity.paymentdetails.account_holder_dofb }}</span>
          </li>
        </ul>
      </b-col>
    </b-row>

    <!-- Signature -->
    <div>
      <h4 class="mb-2">
        Signature
      </h4>
      <b-img
        :src="entity.sign_path"
        height="200"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'EntityPayment',
  props: { entity: { type: Object, default: () => {} } },
}
</script>
<style lang="scss">

</style>
