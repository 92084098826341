<template>
  <div>
    <div>
      <inpo-view v-if="entityType === 'Islamic Org'" />
      <business-view v-else-if="entityType === 'Business'" />
      <organizer-view
        v-else-if="entityType === 'Event-Organization'"
      />
    </div>
  </div>
</template>
<script>
import InpoView from '../inpos/view.vue'
import BusinessView from '../business/view.vue'
import OrganizerView from '../event-organizers/view.vue'

export default {
  name: 'EntityProfile',
  components: { InpoView, BusinessView, OrganizerView },
  computed: {
    entityType() {
      return this.$store.getters['mainEntity/getEntityType']
    },
  },
}
</script>
<style lang="scss">

</style>
