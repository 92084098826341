<template>
  <div>
    <spinner-loader :loading="loading" />

    <div v-if="!loading">
      <!-- Banner -->
      <entity-banner
        :entity="formattedEntity"
        edit-route="inpo-edit"
      />
      <b-tabs class="mt-2">
        <!-- Org Details -->
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" />
            <span>Organization Details</span>
          </template>
          <entity-details
            class="grey-border p-3 text-capitalize"
            :entity="formattedEntity"
          />
        </b-tab>
        <!-- Social -->
        <b-tab>
          <template #title>
            <feather-icon icon="Share2Icon" />
            <span>Social</span>
          </template>
          <entity-social
            class="grey-border p-3 text-capitalize"
            :entity="formattedEntity"
          />
        </b-tab>
        <!-- Payment Details -->
        <b-tab>
          <template #title>
            <feather-icon icon="CreditCardIcon" />
            <span>Payment Details</span>
          </template>
          <entity-payment
            class="grey-border p-3 text-capitalize"
            :entity="formattedEntity"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import getInpo from '@/common/compositions/Inpos/getInpo'
import EntityBanner from '@/common/components/common/Entities/EntityBanner.vue'
import EntityDetails from '@/common/components/common/Entities/EntityDetails.vue'
import EntitySocial from '@/common/components/common/Entities/EntitySocial.vue'
import EntityPayment from '@/common/components/common/Entities/EntityPayment.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'OrgView',
  components: {
    EntityBanner,
    EntityDetails,
    EntitySocial,
    EntityPayment,
    SpinnerLoader,
  },
  computed: {
    formattedEntity() {
      return {
        ...this.inpoData, cover: this.inpoData.cover[0], logo: this.inpoData.logo[0], sign_path: this.inpoData.sign_path[0],
      }
    },
    loading() {
      return !Object.values(this.inpoData).length
    },
  },
  created() {
    this.getInpoData()
  },
  setup() {
    const { inpoData, getInpoData } = getInpo()
    return {
      inpoData,
      getInpoData,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
