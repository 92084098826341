<template>
  <div>
    <div class="text-right">
      <b-button
        v-if="$can('update','EntitiesController')"
        variant="primary"
        class="mb-1"
        :to="{name:'event-organizer-edit',params:{id:$route.params.id || $store.getters['mainEntity/getEntityId']} }"
      >
        Edit
      </b-button>
    </div>

    <b-card header="View Organizer">
      <spinner-loader :loading="loading" />
      <event-organizer-form
        v-if="!loading"
        :organizer="organizer"
        disabled
      />

    </b-card>
  </div>
</template>

<script>
import EventOrganizerForm from '@/common/components/EventOrganizers/EventOrganizerForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'ViewEventOrganizer',
  components: {
    EventOrganizerForm,
    SpinnerLoader,
  },
  data() {
    return {
      organizer: {},
      loading: true,
    }
  },
  created() {
    this.getOrganizer()
  },
  methods: {
    getOrganizer() {
      this.$entities.get(`/internalops/organizer/${this.$route.params.id ?? this.$store.getters['mainEntity/getEntityId']}`).then(res => {
        this.organizer = {
          ...res.data.data,
          logo: [res.data.data.logo],
          location: res.data.data.address[0] && res.data.data.location
            ? {
              ...(res.data.data.address[0]),
              lat: res.data.data.location.coordinates[1],
              lng: res.data.data.location.coordinates[0],
            } : {},
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
