<template>
  <div>
    <spinner-loader :loading="loading" />

    <div v-if="!loading">
      <!-- Banner -->
      <entity-banner
        :entity="formattedEntity"
        edit-route="edit-business"
      />

      <b-tabs class="mt-2">
        <!-- Business Details -->
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" />
            <span>Business Details</span>
          </template>
          <entity-details
            class="grey-border p-3 text-capitalize"
            :entity="formattedEntity"
          />
          <carousel
            v-if="formattedEntity.media_files.length"
            :images="formattedEntity.media_files"
          />
        </b-tab>

        <!-- Social -->
        <b-tab>
          <template #title>
            <feather-icon icon="Share2Icon" />
            <span>Social</span>
          </template>
          <entity-social
            class="grey-border p-3 text-capitalize"
            :entity="formattedEntity"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import getBusiness from '@/common/compositions/Business/getBusiness'
import EntityDetails from '@/common/components/common/Entities/EntityDetails.vue'
import EntityBanner from '@/common/components/common/Entities/EntityBanner.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import EntitySocial from '@/common/components/common/Entities/EntitySocial.vue'
import Carousel from '@/common/components/Announcements/CustomCarousel.vue'

export default {
  name: 'BusinessView',
  components: {
    EntityDetails,
    EntityBanner,
    SpinnerLoader,
    EntitySocial,
    Carousel,
  },
  computed: {
    formattedEntity() {
      return {
        ...this.businessData, cover: this.businessData.cover[0], logo: this.businessData.logo[0],
      }
    },
    loading() {
      return !Object.values(this.businessData).length
    },
  },
  setup() {
    const { businessData, getBusinessData } = getBusiness()
    return {
      avatarText,
      businessData,
      getBusinessData,
    }
  },
  created() {
    this.getBusinessData()
  },
}
</script>

<style>
</style>
